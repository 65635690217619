<template>
  <div>
    <span v-if="!isConnected">
      <v-tooltip top color="error">
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" @click="reconnect">
            <v-icon color="red">mdi-message-alert-outline</v-icon>
            <span class="hidden-sm-and-down">{{ $t('disconnected') }}</span>
          </span>
        </template>
        <span>{{ $t('messagingDisconnected') }}</span>
      </v-tooltip>
    </span>

    <v-icon v-if="isConnected">mdi-message-outline</v-icon>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters('user', ['tenantId', 'user']),
    ...mapGetters('messaging', [
      'connectionState',
      'isConnected',
      'unreadMessageCount'
    ])
  },
  methods: {
    ...mapActions('messages', ['addMessage']),
    async reconnect() {
      try {
        await this.$messageHub.reconnect(this.user, this.tenantId)
        this.addMessage({
          message: this.$t('messagingConnected'),
          type: 'success'
        })
      } catch (e) {
        this.addMessage({
          message: this.$t('messagingReconnectFailed'),
          type: 'error'
        })
      }
    }
  }
}
</script>
