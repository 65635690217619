<template>
  <div>
    <Loading v-if="loading" :error="error" text="Loading" :loading="loading"></Loading>

    <v-container v-if="isAuthenticatedRoute && !loading && !tenantId">

      <v-app-bar app color="#D1D3D4" clipped-left extension-height="48">
        <v-app-bar-nav-icon @click.stop="goHome">
          <v-img :alt="theme.title" class="shrink mr-2 ml-4" contain :src="require(`@/assets/${theme.src}`)"
            transition="scale-transition" width="40" />
        </v-app-bar-nav-icon>
        <v-toolbar-title id="title">{{ theme.title }}</v-toolbar-title>
      </v-app-bar>
      <v-main class="mt-6">
        <v-card class="mx-auto" max-width="800">
          <v-card-title>{{ $t('authfailure') }}</v-card-title>
          <v-card-text>
            <div v-if="!tenantId">
              No user organziation can be determined for <b>{{ user.email }}</b>. Choose a different user account or contact support.
              <br/>
              <a @click="showDetail = !showDetail">{{ !showDetail ? 'show details' : 'hide details' }}...</a>
            </div>
            <div v-if="showDetail">
              <pre>{{ user }}</pre>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn text large @click="help" color="primary">
              {{ $t('help') }}
            </v-btn>
            <v-spacer/>
            <v-btn link large @click="doLogout" color="primary">
              {{ $t('logout') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-main>
    </v-container>

    <div v-if="!loading && (tenantId || !isAuthenticatedRoute)">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Loading from '@/components/loading'

export default {
  components: {
    Loading
  },
  data()
  {
    return {
      isAnonymousRouteData: false,
      showDetail: false
    }
  },
  computed: {
    ...mapGetters('app', ['error', 'loading', 'config']),
    ...mapGetters('user', ['isAuthenticated', 'tenantId', 'user', 'expiresAt']),
    ...mapGetters('theme', ['theme']),
    r()
    {
      const r = this.$route
      console.log('route', r)
      return r
    },
    isAuthenticatedRoute()
    {
      return this.$route.meta && this.$route.meta.isAuthenticated == true
    }
  },
  methods: {
    ...mapActions('user', ['logout']),
    async doLogout() {
      await this.logout()
    },
    help() {
      window.open(this.config.helpUrl, '_blank')
    },
  }
}
</script>
