<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import messageHub from '../hub'

export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('user', ['isAuthenticated', 'tenantId', 'user']),
  },
  watch: {
    async tenantId(val) {
      if (val) {
        console.log('starting message hub')
        await this.start()
      }
    }
  },
  mounted() {
    if(this.tenantId) {
      this.start()
    }
  },
  created() {
    messageHub.connection.onreconnected(() => {
      this.setConnectionState(messageHub.connection.state)
    })

    messageHub.connection.on('connect', () => {
      this.setConnectionState(messageHub.connection.state)
    })

    messageHub.connection.on('userJoinedTenant', () => {
      this.setConnectionState(messageHub.connection.state)
    })

    messageHub.connection.on('messageCreated', () => {
      this.updateUnreadMessages(this.$store, { tenantId: this.tenantId })
    })

    messageHub.connection.on('channelcreated', channel => {
      this.$store.dispatch('messaging/addCreatedChannel', channel)
    })
  },
  methods: {
    ...mapActions('messaging', ['init', 'setConnectionState']),
    async start() {
      if (this.tenantId && this.user) {
        await this.init({ tenantId: this.tenantId })
        await messageHub.start(this.user, this.tenantId)
        this.setConnectionState(messageHub.connection.state)
      }
    },
    updateUnreadMessages: _.debounce((store, payload) => {
      store.dispatch('messaging/updateUnreadMessages', {
        tenantId: payload.tenantId
      })
    }, 2000)
  }
}
</script>
