<template>
  <div class="loading-screen">
    <v-card flat>
      <v-card-text class="text-center">
        <div v-if="!error">
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <div class="text-h5 pt-4">{{ text  }}</div>
        </div>
        <v-alert v-if="error" type="error">
          {{ error }}
        </v-alert>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default{
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: Error,
      default: null
    },
    text: {
      type: String,
      default: 'Loading...'
    }
  }
}
</script>
<style scoped>
.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
}
</style>
